import { supabase } from '@/supabase'
import axios from 'axios'

export const service_api_service = axios.create({
  baseURL: import.meta.env.VITE_SERVICE_URL,
})

export const get_auth_header = async () => {
  const { data: { session: { access_token } } } = await supabase.auth.getSession()
  return {
    Authorization: `Bearer ${ access_token }`
  }
}
