import './index.css'

import { createApp }  from 'vue'
import App            from './App.vue'
import router         from './router'
import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v3'
import * as Sentry    from '@sentry/vue'
import LogRocket      from 'logrocket'

const app = createApp(App)

Sentry.init({
  app,
  environment: import.meta.env.VITE_ENV,
  dsn: 'https://0d0a95eaef246019de7c9f41cf6cad0a@o443325.ingest.us.sentry.io/4507982775451648',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [
        window.location.origin,
        /^https:\/\/service\.api\.comparepower\.com\/api/,
        /^https:\/\/service\.api\.dev\.comparepower\.com\/api/
      ]
    }),
    Sentry.replayCanvasIntegration()
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/service\.api\.comparepower\.com\/api/, /^https:\/\/service\.api\.dev\.comparepower\.com\/api/],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0
})

LogRocket.init('luc7ds/user-portal')

app.use(router)

app.use(fpjsPlugin, {
  loadOptions: {
    apiKey: import.meta.env.VITE_FINGERPRINT_API_KEY,
    endpoint: import.meta.env.VITE_FINGERPRINT_ENDPOINT,
    scriptUrlPattern: import.meta.env.VITE_FINGERPRINT_SCRIPT_URL_PATTERN
  }
})

app.mount('#app')
