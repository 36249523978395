import Auth             from '@/composables/Auth'
import { ref }          from 'vue'
import { knock_client } from '@/knock'
import { log_event }    from '@/composables/helpers/datalayer_service'
import ServiceLocations from '@/composables/ServiceLocations'

const notifications = ref([])
const notifications_menu_open = ref(false)
const channel_id = import.meta.env.VITE_KNOCK_CHANNEL_ID
const feed_client = ref()
const { update_location_in_service_locations } = ServiceLocations()

const initialize_notifications_feed = async () => {
  const { user } = Auth()
  knock_client.authenticate(user.value.id)


  feed_client.value = knock_client.feeds.initialize(channel_id, {
    archived: 'exclude',
    workflow_categories: ['live-link'],
    page_size: 10
  })

  setup_feed_events()
  feed_client.value.listenForUpdates()
  feed_client.value.fetch({ filter: { archived: false } })
  mark_all_as_seen()
}

const setup_feed_events = () => {
  if (!feed_client.value) return

  feed_client.value.on('items.received.page', ({ items }) => {
    items.forEach((item) => {
      if (!notifications.value.some((notification) => notification.id === item.id)) {
        notifications.value.push(item)
      }
    })
  })

  feed_client.value.on('items.received.realtime', async ({ items }) => {
    for (const item of items) {
      process_new_notification(item)
    }
  })

  feed_client.value.on('items:archived', ({ items }) => {
    notifications.value = notifications.value.filter(notification => !items.some(item => item.id === notification.id))
  })

  feed_client.value.on('items:seen', ({ items }) => update_notifications_key_date(items, 'seen_at'))
  feed_client.value.on('items:all_seen', () => update_notifications_key_date(notifications.value, 'seen_at'))
  feed_client.value.on('items:read', ({ items }) => update_notifications_key_date(items, 'read_at'))
  feed_client.value.on('items:all_read', () => update_notifications_key_date(notifications.value, 'read_at'))
}

const mark_notification_as_read = (notification) => {
  if (!notification.read_at) feed_client.value.markAsRead(notification)
}

const mark_notification_as_seen = (notification) => {
  if (!notification.seen_at) feed_client.value.markAsSeen(notification)
}

const mark_all_as_seen = () => {
  feed_client.value.markAllAsSeen()
}

const mark_notification_as_archived = async (notification) => {
  await feed_client.value.markAsArchived(notification)
  await feed_client.value.fetch({ filter: { archived: false } })
}

const mark_all_as_read = () => {
  feed_client.value.markAllAsRead()
}

const process_new_notification = (notification) => {
  mark_notification_as_seen(notification)

  if (notifications_menu_open.value) {
    mark_notification_as_read(notification)
  }

  if (notification.data?.service_location.service_location.esiid) {
    update_location_in_service_locations({ esiid: notification.data.service_location.service_location.esiid })
  }

  if (notification.source?.key === 'live-link-initial-setup-complete' && notification.data?.is_success) {
    log_event({
      name: 'mem_livelink_request_success',
      esiid: notification.data.service_location?.service_location?.esiid,
      type: 'live_link'
    })
  }

  notifications.value?.unshift(notification)
}

const close_notifications_feed = () => {
  if (feed_client.value) feed_client.value.teardown()
}

const update_notifications_key_date = (items, key) => {
  items.forEach((item) => {
    const notification = notifications.value.find((notification) => notification.id === item.id)
    if (notification && !notification[key]) {
      notification[key] = new Date().toISOString()
    }
  })
}

export default () => ({
  notifications,
  notifications_menu_open,
  initialize_notifications_feed,
  close_notifications_feed,
  mark_notification_as_archived,
  mark_notification_as_seen,
  mark_notification_as_read,
  mark_all_as_seen,
  mark_all_as_read,
  feed_client
})