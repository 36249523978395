
export const rep_list = [
    {
        "puct_number": "10260",
        "name": "175 POWER GLOBAL RETAIL TEXAS LLC"
    },
    {
        "puct_number": "10087",
        "name": "3000 Energy Corp"
    },
    {
        "puct_number": "10041",
        "name": "4CHANGE *"
    },
    {
        "puct_number": "10041",
        "name": "4CHANGE ENERGY *"
    },
    {
        "puct_number": "10298",
        "name": "ABACUS ENERGY RETAIL ELECTRICITY *"
    },
    {
        "puct_number": "10314",
        "name": "Abundance Energy *"
    },
    {
        "puct_number": "10137",
        "name": "ACACIA ENERGY *"
    },
    {
        "puct_number": "10129",
        "name": "ACCEL ENERGY *"
    },
    {
        "puct_number": "10078",
        "name": "ACCENT ENERGY *"
    },
    {
        "puct_number": "10078",
        "name": "ACCENT ENERGY TEXAS LP"
    },
    {
        "puct_number": "10074",
        "name": "ALLIANCE POWER COMPANY LLC"
    },
    {
        "puct_number": "10278",
        "name": "ALMIKA POWER *"
    },
    {
        "puct_number": "10117",
        "name": "AMBIT *"
    },
    {
        "puct_number": "10117",
        "name": "AMBIT ENERGY *"
    },
    {
        "puct_number": "10117",
        "name": "AMBIT TEXAS LLC"
    },
    {
        "puct_number": "10224",
        "name": "AMERICAN POWERNET MANAGEMENT LP"
    },
    {
        "puct_number": "10092",
        "name": "Amigo Commercial *"
    },
    {
        "puct_number": "10081",
        "name": "AMIGO ENERGY *"
    },
    {
        "puct_number": "10310",
        "name": "AMMPER POWER LLC"
    },
    {
        "puct_number": "10278",
        "name": "Ampra Energy *"
    },
    {
        "puct_number": "10105",
        "name": "AP COMMERCIAL *"
    },
    {
        "puct_number": "10105",
        "name": "AP GAS & ELECTRIC (TX) LLC"
    },
    {
        "puct_number": "10105",
        "name": "APG&E *"
    },
    {
        "puct_number": "10105",
        "name": "APG&E ENERGY SOLUTIONS *"
    },
    {
        "puct_number": "10303",
        "name": "ARROW ENERGY TX LLC"
    },
    {
        "puct_number": "10004",
        "name": "ASSURANCE ENERGY *"
    },
    {
        "puct_number": "10227",
        "name": "Axon Power *"
    },
    {
        "puct_number": "10227",
        "name": "AXON POWER & GAS LLC"
    },
    {
        "puct_number": "10312",
        "name": "AXPO US LLC"
    },
    {
        "puct_number": "10278",
        "name": "Beyond Energy *"
    },
    {
        "puct_number": "10323",
        "name": "BKV Electric *"
    },
    {
        "puct_number": "10323",
        "name": "BKV Electricity *"
    },
    {
        "puct_number": "10323",
        "name": "BKV Energy *"
    },
    {
        "puct_number": "10323",
        "name": "BKV Retail Power *"
    },
    {
        "puct_number": "10323",
        "name": "BKV-BPP RETAIL LLC"
    },
    {
        "puct_number": "10323",
        "name": "BKV-BPP RETAIL TEXAS *"
    },
    {
        "puct_number": "10038",
        "name": "BP ENERGY COMPANY"
    },
    {
        "puct_number": "10294",
        "name": "BP Energy Holding Company LLC"
    },
    {
        "puct_number": "10153",
        "name": "BP Energy Retail Company LLC"
    },
    {
        "puct_number": "10295",
        "name": "Branch Energy *"
    },
    {
        "puct_number": "10295",
        "name": "BRANCH ENERGY (TEXAS) LLC"
    },
    {
        "puct_number": "10129",
        "name": "BRIGHTSTAR POWER  *"
    },
    {
        "puct_number": "10137",
        "name": "BROOKLET ENERGY DISTRIBUTION LLC"
    },
    {
        "puct_number": "10017",
        "name": "CALPINE ENERGY SOLUTIONS LLC"
    },
    {
        "puct_number": "10293",
        "name": "CAPITAL ENERGY PA LLC"
    },
    {
        "puct_number": "10098",
        "name": "CHAMPION ENERGY INDUSTRIAL SERVICES I *"
    },
    {
        "puct_number": "10098",
        "name": "CHAMPION ENERGY INDUSTRIAL SERVICES II *"
    },
    {
        "puct_number": "10098",
        "name": "CHAMPION ENERGY INDUSTRIAL SERVICES III *"
    },
    {
        "puct_number": "10098",
        "name": "CHAMPION ENERGY SERVICES LLC"
    },
    {
        "puct_number": "10260",
        "name": "CHARIOT ENERGY *"
    },
    {
        "puct_number": "10216",
        "name": "Circular Energy *"
    },
    {
        "puct_number": "10177",
        "name": "CIRRO ENERGY *"
    },
    {
        "puct_number": "10268",
        "name": "CleanSky Energy *"
    },
    {
        "puct_number": "10129",
        "name": "CLEARVIEW ENERGY *"
    },
    {
        "puct_number": "10129",
        "name": "CLEARVIEW POWER INC"
    },
    {
        "puct_number": "10286",
        "name": "CLYDE ENERGY *"
    },
    {
        "puct_number": "10177",
        "name": "COMPASSION ENERGY *"
    },
    {
        "puct_number": "10037",
        "name": "CONOCOPHILLIPS COMPANY"
    },
    {
        "puct_number": "10014",
        "name": "Constellation *"
    },
    {
        "puct_number": "10014",
        "name": "CONSTELLATION NEWENERGY INC"
    },
    {
        "puct_number": "10174",
        "name": "COST PLUS ENERGY *"
    },
    {
        "puct_number": "10110",
        "name": "Cost Plus Power *"
    },
    {
        "puct_number": "10023",
        "name": "CPL *"
    },
    {
        "puct_number": "10011",
        "name": "CPL BUSINESS *"
    },
    {
        "puct_number": "10023",
        "name": "CPL RETAIL ENERGY *"
    },
    {
        "puct_number": "10023",
        "name": "CPL RETAIL ENERGY LP"
    },
    {
        "puct_number": "10309",
        "name": "DAVID ENERGY SUPPLY (TEXAS) LLC"
    },
    {
        "puct_number": "10281",
        "name": "DECLARATION ENERGY LLC"
    },
    {
        "puct_number": "10040",
        "name": "DIRECT ENERGY *"
    },
    {
        "puct_number": "10011",
        "name": "DIRECT ENERGY BUSINESS LLC"
    },
    {
        "puct_number": "10040",
        "name": "DIRECT ENERGY BUSINESS SERVICES *"
    },
    {
        "puct_number": "10040",
        "name": "DIRECT ENERGY LP"
    },
    {
        "puct_number": "10040",
        "name": "DIRECT ENERGY MULTI-FAMILY *"
    },
    {
        "puct_number": "10177",
        "name": "Discount Power *"
    },
    {
        "puct_number": "10078",
        "name": "DYNOWATT *"
    },
    {
        "puct_number": "10078",
        "name": "DYNOWATT POWERED BY ACCENT ENERGY *"
    },
    {
        "puct_number": "10064",
        "name": "EAGLE ENERGY *"
    },
    {
        "puct_number": "10246",
        "name": "ELIGO ENERGY TX LLC"
    },
    {
        "puct_number": "10291",
        "name": "ENEL TRADING NORTH AMERICA LLC"
    },
    {
        "puct_number": "10064",
        "name": "Energy Rewards *"
    },
    {
        "puct_number": "10281",
        "name": "Energy Texas *"
    },
    {
        "puct_number": "10259",
        "name": "Energy to Go *"
    },
    {
        "puct_number": "10053",
        "name": "ENGIE RESOURCES LLC"
    },
    {
        "puct_number": "10205",
        "name": "EPIQ ENERGY *"
    },
    {
        "puct_number": "10262",
        "name": "Evolve *"
    },
    {
        "puct_number": "10262",
        "name": "Evolve Energy *"
    },
    {
        "puct_number": "10011",
        "name": "EXPERT ENERGY *"
    },
    {
        "puct_number": "10041",
        "name": "Express Energy *"
    },
    {
        "puct_number": "10008",
        "name": "FIRST CHOICE POWER *"
    },
    {
        "puct_number": "10008",
        "name": "FIRST CHOICE POWER LLC"
    },
    {
        "puct_number": "10298",
        "name": "Flagship Power *"
    },
    {
        "puct_number": "10248",
        "name": "FREEPOINT ENERGY SOLUTIONS LLC"
    },
    {
        "puct_number": "10169",
        "name": "FRONTIER UTILITIES *"
    },
    {
        "puct_number": "10169",
        "name": "FRONTIER UTILITIES LLC"
    },
    {
        "puct_number": "10081",
        "name": "FULCRUM RETAIL ENERGY LLC"
    },
    {
        "puct_number": "10027",
        "name": "GEXA CORP *"
    },
    {
        "puct_number": "10027",
        "name": "GEXA ENERGY *"
    },
    {
        "puct_number": "10027",
        "name": "GEXA ENERGY CORP *"
    },
    {
        "puct_number": "10027",
        "name": "GEXA ENERGY LP"
    },
    {
        "puct_number": "10027",
        "name": "GEXA ENERGY.COM *"
    },
    {
        "puct_number": "10308",
        "name": "GOOD CHARLIE & CO LLC"
    },
    {
        "puct_number": "10308",
        "name": "GOOD CHARLIE OPS LLC *"
    },
    {
        "puct_number": "10009",
        "name": "Green Mountain *"
    },
    {
        "puct_number": "10009",
        "name": "Green Mountain Energy *"
    },
    {
        "puct_number": "10009",
        "name": "GREEN MOUNTAIN ENERGY COMPANY"
    },
    {
        "puct_number": "10098",
        "name": "Hello Energy *"
    },
    {
        "puct_number": "10251",
        "name": "HERITAGE POWER LLC"
    },
    {
        "puct_number": "10092",
        "name": "HUDSON ENERGY SERVICES LLC"
    },
    {
        "puct_number": "10078",
        "name": "IGS ENERGY *"
    },
    {
        "puct_number": "10299",
        "name": "INFUSE ENERGY *"
    },
    {
        "puct_number": "10269",
        "name": "INSPIRE ENERGY HOLDINGS LLC"
    },
    {
        "puct_number": "10289",
        "name": "IRONHORSE POWER SERVICES LLC"
    },
    {
        "puct_number": "10278",
        "name": "JP ENERGY RESOURCES LLC"
    },
    {
        "puct_number": "10298",
        "name": "Juice *"
    },
    {
        "puct_number": "10052",
        "name": "JUST ENERGY *"
    },
    {
        "puct_number": "10092",
        "name": "Just Energy Commercial *"
    },
    {
        "puct_number": "10052",
        "name": "JUST ENERGY RGV *"
    },
    {
        "puct_number": "10052",
        "name": "JUST ENERGY TEXAS LP"
    },
    {
        "puct_number": "10259",
        "name": "Lone Star Energy *"
    },
    {
        "puct_number": "10305",
        "name": "MARKET PLACE ENERGY LLC"
    },
    {
        "puct_number": "10333",
        "name": "MEMS INDUSTRIAL SUPPLY LLC"
    },
    {
        "puct_number": "10325",
        "name": "METROPLEX ENERGY INC"
    },
    {
        "puct_number": "10298",
        "name": "MI TEXAS REP 1 LLC"
    },
    {
        "puct_number": "10315",
        "name": "MI TEXAS REP 2 LLC"
    },
    {
        "puct_number": "10233",
        "name": "MIDAMERICAN ENERGY SERVICES LLC"
    },
    {
        "puct_number": "10110",
        "name": "Mio Electric *"
    },
    {
        "puct_number": "10298",
        "name": "Mothership *"
    },
    {
        "puct_number": "10174",
        "name": "MP2 ENERGY TEXAS LLC"
    },
    {
        "puct_number": "10283",
        "name": "NATIONAL GAS & ELECTRIC TEXAS LLC"
    },
    {
        "puct_number": "10166X",
        "name": "NEC Co-op Energy"
    },
    {
        "puct_number": "10166X",
        "name": "NEC RETAIL *"
    },
    {
        "puct_number": "10040",
        "name": "NEW LEAF ENERGY  *"
    },
    {
        "puct_number": "10259",
        "name": "New Power Texas *"
    },
    {
        "puct_number": "10014",
        "name": "NEWENERGY *"
    },
    {
        "puct_number": "10126",
        "name": "NOVA ELECTRIC CO *"
    },
    {
        "puct_number": "10137",
        "name": "NOW POWER *"
    },
    {
        "puct_number": "10011",
        "name": "NRG *"
    },
    {
        "puct_number": "10011",
        "name": "NRG Business *"
    },
    {
        "puct_number": "10311",
        "name": "NRG CONTROLLABLE LOAD SERVICES LLC"
    },
    {
        "puct_number": "10166X",
        "name": "NUECES ELECTRIC COOPERATIVE (DISTRIBUTION SERVICE PROVIDER) *"
    },
    {
        "puct_number": "10262",
        "name": "Octopus *"
    },
    {
        "puct_number": "10262",
        "name": "Octopus Energy *"
    },
    {
        "puct_number": "10262",
        "name": "Octopus Energy LLC"
    },
    {
        "puct_number": "10280",
        "name": "OhmConnect Energy *"
    },
    {
        "puct_number": "10280",
        "name": "OHMCONNECT TEXAS LLC"
    },
    {
        "puct_number": "10326",
        "name": "ONPOINT ENERGY TEXAS LLC"
    },
    {
        "puct_number": "10170",
        "name": "OUR ENERGY LLC"
    },
    {
        "puct_number": "10110",
        "name": "PAYLESS POWER *"
    },
    {
        "puct_number": "10177",
        "name": "PENNYWISE  *"
    },
    {
        "puct_number": "10177",
        "name": "PENNYWISE POWER  *"
    },
    {
        "puct_number": "10087",
        "name": "PENSTAR POWER *"
    },
    {
        "puct_number": "10087",
        "name": "PENSTAR POWER LLC *"
    },
    {
        "puct_number": "10087",
        "name": "PENSTAR POWER LLC 1 *"
    },
    {
        "puct_number": "10328",
        "name": "PERIDOT POWER LLC"
    },
    {
        "puct_number": "10278",
        "name": "Peso Power *"
    },
    {
        "puct_number": "10228",
        "name": "Pogo Energy *"
    },
    {
        "puct_number": "10228",
        "name": "POGO ENERGY LLC"
    },
    {
        "puct_number": "10064",
        "name": "POWER HOUSE ENERGY *"
    },
    {
        "puct_number": "10259",
        "name": "PowerNext *"
    },
    {
        "puct_number": "10280",
        "name": "POWERPASS *"
    },
    {
        "puct_number": "10278",
        "name": "Prepay Power *"
    },
    {
        "puct_number": "10216",
        "name": "PRO POWER PROVIDERS LLC"
    },
    {
        "puct_number": "10205",
        "name": "PRONTO POWER  *"
    },
    {
        "puct_number": "10259",
        "name": "PULSE POWER LLC"
    },
    {
        "puct_number": "10314",
        "name": "QUEXT ENERGY LLC"
    },
    {
        "puct_number": "10264",
        "name": "Ranchero Power *"
    },
    {
        "puct_number": "10007",
        "name": "RELIANT *"
    },
    {
        "puct_number": "10007",
        "name": "RELIANT ENERGY *"
    },
    {
        "puct_number": "10007",
        "name": "RELIANT ENERGY BUSINESS SERVICES *"
    },
    {
        "puct_number": "10007",
        "name": "RELIANT ENERGY RETAIL SERVICES LLC"
    },
    {
        "puct_number": "10299",
        "name": "REVOLUTION ENERGY *"
    },
    {
        "puct_number": "10299",
        "name": "REVOLUTION ENERGY LLC"
    },
    {
        "puct_number": "10279",
        "name": "RHYTHM *"
    },
    {
        "puct_number": "10279",
        "name": "Rhythm Energy *"
    },
    {
        "puct_number": "10279",
        "name": "RHYTHM OPS LLC"
    },
    {
        "puct_number": "10126",
        "name": "Rio Power & Light *"
    },
    {
        "puct_number": "10169",
        "name": "RODEO ENERGY *"
    },
    {
        "puct_number": "10334",
        "name": "RPOWER RETAIL LLC"
    },
    {
        "puct_number": "10331",
        "name": "RUSHMORE ENERGY LLC"
    },
    {
        "puct_number": "10249",
        "name": "SFE ENERGY TEXAS INC"
    },
    {
        "puct_number": "10104",
        "name": "SG&E *"
    },
    {
        "puct_number": "10174",
        "name": "Shell Energy Solutions *"
    },
    {
        "puct_number": "10051",
        "name": "SMART PREPAID ELECTRIC *"
    },
    {
        "puct_number": "10253",
        "name": "SMARTENERGY HOLDINGS LLC"
    },
    {
        "puct_number": "10300",
        "name": "SMARTESTENERGY US LLC"
    },
    {
        "puct_number": "10110",
        "name": "SmarTricity *"
    },
    {
        "puct_number": "10169",
        "name": "SOL ENERGY *"
    },
    {
        "puct_number": "10264",
        "name": "SOUTHERN FEDERAL POWER LLC"
    },
    {
        "puct_number": "10126",
        "name": "SOUTHWEST POWER & LIGHT *"
    },
    {
        "puct_number": "10046",
        "name": "SPARK ENERGY LLC"
    },
    {
        "puct_number": "10014",
        "name": "STARTEX POWER *"
    },
    {
        "puct_number": "10202",
        "name": "STAT Energy LLC"
    },
    {
        "puct_number": "10104",
        "name": "STREAM ENERGY *"
    },
    {
        "puct_number": "10104",
        "name": "STREAM SPE LTD"
    },
    {
        "puct_number": "10205",
        "name": "SUMMER ENERGY LLC"
    },
    {
        "puct_number": "10293",
        "name": "Sunrise Power *"
    },
    {
        "puct_number": "10293",
        "name": "SUNRISE POWER & GAS TEXAS *"
    },
    {
        "puct_number": "10092",
        "name": "Tara Commercial *"
    },
    {
        "puct_number": "10051",
        "name": "TARA ENERGY *"
    },
    {
        "puct_number": "10051",
        "name": "TARA ENERGY LLC"
    },
    {
        "puct_number": "10081",
        "name": "TARA ENERGY RESOURCES *"
    },
    {
        "puct_number": "10081",
        "name": "TARA POWER *"
    },
    {
        "puct_number": "10296",
        "name": "TESLA *"
    },
    {
        "puct_number": "10296",
        "name": "TESLA ENERGY VENTURES LLC"
    },
    {
        "puct_number": "10065",
        "name": "TEXAS RETAIL ENERGY LLC"
    },
    {
        "puct_number": "10126",
        "name": "TEXPO ENERGY *"
    },
    {
        "puct_number": "10126",
        "name": "TEXPO POWER LP"
    },
    {
        "puct_number": "10315",
        "name": "Think Energy *"
    },
    {
        "puct_number": "10268",
        "name": "TITAN GAS AND POWER *"
    },
    {
        "puct_number": "10268",
        "name": "TITAN GAS LLC"
    },
    {
        "puct_number": "10270",
        "name": "TOMORROW ENERGY CORP"
    },
    {
        "puct_number": "10241",
        "name": "TotalEnergies Gas & Power North America Inc"
    },
    {
        "puct_number": "10258",
        "name": "TOWN SQUARE ENERGY LLC"
    },
    {
        "puct_number": "10064",
        "name": "TRIEAGLE ENERGY LP"
    },
    {
        "puct_number": "10064",
        "name": "TRIEAGLE ENERGY SERVICES *"
    },
    {
        "puct_number": "10260",
        "name": "Tristar Energy *"
    },
    {
        "puct_number": "10287",
        "name": "TRUE COMMODITIES LLC"
    },
    {
        "puct_number": "10287",
        "name": "TRUE POWER *"
    },
    {
        "puct_number": "10287",
        "name": "True Prepaid *"
    },
    {
        "puct_number": "10004",
        "name": "TXU ENERGY *"
    },
    {
        "puct_number": "10004",
        "name": "TXU ENERGY RETAIL COMPANY LLC"
    },
    {
        "puct_number": "10110",
        "name": "UNO ENERGY *"
    },
    {
        "puct_number": "10052",
        "name": "US ENERGY SAVINGS CORP *"
    },
    {
        "puct_number": "10177",
        "name": "US RETAILERS LLC"
    },
    {
        "puct_number": "10041",
        "name": "VALUE BASED BRANDS LLC"
    },
    {
        "puct_number": "10293",
        "name": "Value Energy *"
    },
    {
        "puct_number": "10293",
        "name": "Value Power *"
    },
    {
        "puct_number": "10271",
        "name": "VARSITY ENERGY LLC"
    },
    {
        "puct_number": "10041",
        "name": "Veteran Energy *"
    },
    {
        "puct_number": "10064",
        "name": "Viridian Energy *"
    },
    {
        "puct_number": "10137",
        "name": "VITAL POWER *"
    },
    {
        "puct_number": "10264",
        "name": "Watt Bro *"
    },
    {
        "puct_number": "10022",
        "name": "WTU *"
    },
    {
        "puct_number": "10011",
        "name": "WTU BUSINESS *"
    },
    {
        "puct_number": "10022",
        "name": "WTU RETAIL ENERGY  *"
    },
    {
        "puct_number": "10022",
        "name": "WTU RETAIL ENERGY LP"
    },
    {
        "puct_number": "10203",
        "name": "XOOM ENERGY TEXAS LLC"
    },
    {
        "puct_number": "10286",
        "name": "YENTA ENERGY LP"
    },
    {
        "puct_number": "10126",
        "name": "YEP *"
    },
    {
        "puct_number": "10110",
        "name": "YOUNG ENERGY LLC"
    },
    {
        "puct_number": "10105",
        "name": "ZIP ENERGY *"
    },
    {
        "puct_number": "10306",
        "name": "AMBER ENERGY SERVICES LLC"
    },
    {
        "puct_number": "10250",
        "name": "AMOS ENERGY INC"
    },
    {
        "puct_number": "10079",
        "name": "APN STARFIRST LP"
    },
    {
        "puct_number": "10304",
        "name": "BROAD REACH POWER ENERGY SERVICES LLC"
    },
    {
        "puct_number": "10168",
        "name": "BUSHEIDO ENERGY LLC *"
    },
    {
        "puct_number": "10015",
        "name": "CALPINE POWER AMERICA LLC"
    },
    {
        "puct_number": "10237",
        "name": "CITIGROUP ENERGY INC"
    },
    {
        "puct_number": "10232",
        "name": "Crypto Power *"
    },
    {
        "puct_number": "10265",
        "name": "DEMAND CONTROL 2 LLC"
    },
    {
        "puct_number": "10082",
        "name": "DOW HYDROCARBONS AND RESOURCES LLC"
    },
    {
        "puct_number": "10165",
        "name": "EIF CHANNELVIEW RETAIL ENERGY LLC"
    },
    {
        "puct_number": "10231",
        "name": "ELECTRANET POWER LLC"
    },
    {
        "puct_number": "10168",
        "name": "ENERGY TRANSFER RETAIL POWER LLC"
    },
    {
        "puct_number": "10054",
        "name": "EXXONMOBIL POWER AND GAS SERVICES INC"
    },
    {
        "puct_number": "10234",
        "name": "GERDAU AMERISTEEL ENERGY INC"
    },
    {
        "puct_number": "10307",
        "name": "GRIDMATIC ROSA LLC"
    },
    {
        "puct_number": "10317",
        "name": "HIVOLT ENERGY TEXAS LLC"
    },
    {
        "puct_number": "10324",
        "name": "HODL *"
    },
    {
        "puct_number": "10193",
        "name": "JP MORGAN VENTURES ENERGY CORPORATION"
    },
    {
        "puct_number": "10327",
        "name": "LOW COUNTRY POWER LLC"
    },
    {
        "puct_number": "10043",
        "name": "LUMINANT ET SERVICES COMPANY LLC"
    },
    {
        "puct_number": "10225",
        "name": "MERRILL LYNCH COMMODITIES INC"
    },
    {
        "puct_number": "10229",
        "name": "MINOTAUR LEGACY GROUP LLC"
    },
    {
        "puct_number": "10273",
        "name": "MITSUI & CO ENERGY MARKETING AND SERVICES (USA) INC"
    },
    {
        "puct_number": "10261",
        "name": "MORGAN STANLEY CAPITAL GROUP INC"
    },
    {
        "puct_number": "10324",
        "name": "MOTHERSHIP TEXAS REP 11 LLC"
    },
    {
        "puct_number": "10085",
        "name": "NEUMIN PRODUCTION COMPANY"
    },
    {
        "puct_number": "10019",
        "name": "NEXTERA ENERGY MARKETING LLC"
    },
    {
        "puct_number": "10016",
        "name": "OCCIDENTAL POWER MARKETING LP"
    },
    {
        "puct_number": "10285",
        "name": "OLIN CORPORATION"
    },
    {
        "puct_number": "10330",
        "name": "OMEGA US HOLDING II, LLC"
    },
    {
        "puct_number": "10316",
        "name": "ONXY ELECTRIC ENERGY LLC"
    },
    {
        "puct_number": "10208",
        "name": "PARTNERS NEW ENERGY LLC"
    },
    {
        "puct_number": "10320",
        "name": "PRIORITY ENERGY TRANSITION 1 LLC"
    },
    {
        "puct_number": "10319",
        "name": "PRIORITY ENERGY TRANSITION 2 LLC"
    },
    {
        "puct_number": "10318",
        "name": "PRIORITY ENERGY TRANSITION 3 LLC"
    },
    {
        "puct_number": "10322",
        "name": "PRIORITY ENERGY TRANSITION 4 LLC"
    },
    {
        "puct_number": "10321",
        "name": "PRIORITY ENERGY TRANSITION 5 LLC"
    },
    {
        "puct_number": "10232",
        "name": "Pumpjack Power *"
    },
    {
        "puct_number": "10232",
        "name": "Pumpjack Power LLC"
    },
    {
        "puct_number": "10239",
        "name": "REP ACE POWER LLC"
    },
    {
        "puct_number": "10332",
        "name": "SELFSERVE ENERGY CORP"
    },
    {
        "puct_number": "10297",
        "name": "SWITCH LTD"
    },
    {
        "puct_number": "10257",
        "name": "TALEN ENERGY MARKETING LLC"
    },
    {
        "puct_number": "10035",
        "name": "TENASKA POWER SERVICES CO"
    },
    {
        "puct_number": "10061",
        "name": "TENASKA TOPS REP LP"
    },
    {
        "puct_number": "10301",
        "name": "TERRAFORM RENEWABLE ENERGY SERVICES LLC"
    },
    {
        "puct_number": "10061",
        "name": "TOPS I *"
    },
    {
        "puct_number": "10035",
        "name": "TPS 4 *"
    },
    {
        "puct_number": "10035",
        "name": "TPS I *"
    },
    {
        "puct_number": "10035",
        "name": "TPS II *"
    },
    {
        "puct_number": "10035",
        "name": "TPS III *"
    },
    {
        "puct_number": "10242",
        "name": "EROCK ON-SITE LLC"
    },
    {
        "puct_number": "10247",
        "name": "SOLAR STAR PLANO I LLC"
    }
]