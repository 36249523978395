import { computed, ref } from 'vue'
import { supabase }      from '@/supabase'
import { log_event }     from '@/composables/helpers/datalayer_service'
import Notifications     from '@/composables/Notifications'
import { knock_client }  from '@/knock'
import LogRocket         from 'logrocket'
import * as Sentry       from '@sentry/vue'

export const mark_order_processing_complete = async () => {
  await supabase.auth.updateUser({
    data: {
      processed_orders: true
    }
  })
}
export const mark_onboarding_complete = async () => {
  await supabase.auth.updateUser({
    data: {
      generic_onboarding_complete: true
    }
  })
}
const auth_guard = async (to, from, next) => {
  if (is_authenticated.value) {
    if (agreed_to_terms.value) {
      if (has_been_processed.value) {
        if (generic_onboarding_complete.value) {
          next()
        }
        else {
          next({ path: '/add_location' })
        }
      }
      else {
        next({ path: '/process' })
      }
    }
    else {
      next({ path: '/accept_terms' })
    }
  }
  else {
    next({ path: '/login' })
  }
}


const is_authenticated = ref(false)
const user = ref(null)
const picture = computed(() => {
  if (user.value) {
    if (user.value.user_metadata?.first_name && user.value.user_metadata?.last_name) {
      return `https://ui-avatars.com/api/?background=03a9f4&color=fff&name=${user.value.user_metadata.first_name}+${user.value.user_metadata.last_name}`
    }
    if (user.value.email) {
      return `https://ui-avatars.com/api/?background=03a9f4&color=fff&name=${user.value.email}`
    }
    if (user.value.phone) {
      return `https://ui-avatars.com/api/?background=03a9f4&color=fff&name=${user.value.phone}`
    }
    return `https://ui-avatars.com/api/?name=${user.value.id}`
  }
  return null
})

const agreed_to_terms = computed(() => {
  if (user.value) {
    if (user.value.user_metadata) {
      const { first_name, last_name, agreed_to_terms } = user.value.user_metadata
      if (first_name && last_name && agreed_to_terms) {
        return true
      }
    }
  }
  return false
})

const has_been_processed = computed(() => {
  if (user.value) {
    if (user.value.user_metadata) {
      if (user.value.user_metadata.processed_orders) {
        return true
      }
    }
  }
  return false
})

const generic_onboarding_complete = computed(() => {
  if (user.value) {
    if (user.value.user_metadata) {
      if (user.value.user_metadata.generic_onboarding_complete) {
        return true
      }
    }
  }
  return false
})

const email_verification_guard = async (to, from, next) => {
  if (is_authenticated.value) {
    next({ path: '/' })
  }
  else {
    next()
  }
}


const maxAge = 100 * 365 * 24 * 60 * 60 // 100 years, never expires
const expires = new Date(0).toUTCString()
const feed_client = ref()
supabase.auth.onAuthStateChange(async (event, session) => {
  const { initialize_notifications_feed, close_notifications_feed } = Notifications()

  switch (event) {
    case 'SIGNED_IN':
      user.value = session.user
      is_authenticated.value = true
      log_event({
        name: 'auth_event',
        auth_event_type: 'load_authenticated'
      })
      LogRocket?.identify(user.value.id, {
        name: `${user.value.user_metadata.first_name} ${user.value.user_metadata.last_name}`,
        email: user.value.email
      })
      await knock_client?.authenticate(user.value.id)
      await knock_client?.user.identify({
        id: user.value.id,
        email: user.value.email,
        ...user.value.user_metadata.first_name && { name: `${user.value.user_metadata.first_name} ${user.value.user_metadata.last_name}` }
      })
      Sentry?.setUser({
        id: user.value.id,
        email: user.value.email
      })
      initialize_notifications_feed()
    case 'TOKEN_REFRESHED':
      document.cookie = `my-access-token=${session.access_token}; Domain=${import.meta.env.VITE_AUTH_DOMAIN}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
      document.cookie = `my-refresh-token=${session.refresh_token}; Domain=${import.meta.env.VITE_AUTH_DOMAIN}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
      is_authenticated.value = true
      user.value = session.user
      break
    case 'USER_UPDATED':
      user.value = session.user
      break
    case 'SIGNED_OUT':
      document.cookie = `my-access-token=; Domain=${import.meta.env.VITE_AUTH_DOMAIN}; path=/; expires=${expires}; SameSite=Lax; secure`
      document.cookie = `my-refresh-token=; Domain=${import.meta.env.VITE_AUTH_DOMAIN}; path=/; expires=${expires}; SameSite=Lax; secure`
      is_authenticated.value = false
      user.value = null
      close_notifications_feed()
      log_event({ name: 'auth_event', auth_event_type: 'successful_sign_out' })
      window.location.reload()
      break
  }
})

export default () => {
  return {
    auth_guard,
    email_verification_guard,
    is_authenticated,
    user,
    picture,
    agreed_to_terms,
    has_been_processed,
    generic_onboarding_complete,
    feed_client
  }
}