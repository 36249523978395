import { get_auth_header, service_api_service } from '@/services/service_api/index'

export const get_service_location_by_esiid = async ({ esiid }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/service_locations/${ esiid }`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const get_all_service_locations_by_user = async () => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/service_locations`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const get_service_locations_that_have_orders = async () => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/orders/service_locations`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const add_service_location_by_esiid = async ({ esiid }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.post(`/api/service_locations`,
      { esiid },
      { headers }
    )
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const delete_service_location_by_esiid = async ({ esiid }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.delete(`/api/service_locations/${ esiid }`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const delete_all_service_locations_for_user = async () => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.delete(`/api/service_locations`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}
