import { useLocalStorage, useSessionStorage } from '@vueuse/core'

export const email_address = useLocalStorage('email_address', null)

export const redirect_path = useSessionStorage('redirect_url', null)

export const fingerprint_id = useLocalStorage('fingerprint_id', null)

export const renewal_order_id = useSessionStorage('renewal_order_id', null)

export const renewal_email_address = useSessionStorage('renewal_email_address', null)

export const invalid_renewal_email = useSessionStorage('invalid_renewal_email', false)