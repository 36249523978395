import { fingerprint_id } from '@/composables/Storage'
import { log_event } from '@/composables/helpers/datalayer_service'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()
export const set_fingerprint_cookie = async function({ getData }) {
  try {
    if (!fingerprint_id.value) {
      const { visitorId } = await getData({ ignoreCache: true })
      fingerprint_id.value = visitorId
      log_event({ name: 'fpid_set', visitorId })
    }
    cookies.set('cp_fpid', fingerprint_id.value,  '180d', '/', 'comparepower.com', true, 'Lax')
  }
  catch (err) {
    console.error(err)
  }
}
