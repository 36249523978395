<script setup>
import { onMounted, watch }                                               from 'vue'
import { useRoute, useRouter }                                            from 'vue-router'
import Auth                                                               from '@/composables/Auth'
import { set_fingerprint_cookie }                                         from '@/composables/helpers/fingerprint_service'
import { useVisitorData }                                                 from '@fingerprintjs/fingerprintjs-pro-vue-v3'
import { log_event }                                                      from '@/composables/helpers/datalayer_service'
import { invalid_renewal_email, renewal_email_address, renewal_order_id } from '@/composables/Storage'
import { get_order_by_id }                                                from '@/services/service_api/orders'

const { getData } = useVisitorData(
  { extendedResult: true, timeout: 10000 },
  { immediate: false }
)

const { is_authenticated, user, generic_onboarding_complete } = Auth()
const router = useRouter()
onMounted(() => {
  if (is_authenticated.value) {
    redirect()
  }

  watch(is_authenticated, () => {
    redirect()
  })
})

const route = useRoute()
const redirect = async () => {
  if (renewal_email_address.value && user.value.user_metadata.email !== renewal_email_address.value) {
    invalid_renewal_email.value = true
    router.push('/renewal/login')
  }
  else if (renewal_order_id.value && generic_onboarding_complete.value) {
    const order = await get_order_by_id({ id: renewal_order_id.value })
    router.push(`/locations/${order.service_location.esiid}`)
  }
  else {
    await set_fingerprint_cookie({ getData })
    log_event({ name: 'auth_event', auth_event_type: 'successful_sign_in', auth_event_method: 'google' })
    if (route.query.path) {
      router.push({ path: route.query.path })
    }
    else {
      router.push({ path: '/' })
    }
  }
}
</script>

<template>
  <div></div>
</template>

<style scoped>

</style>