import { fingerprint_id } from '@/composables/Storage'
import Auth               from '@/composables/Auth'

const domain = import.meta.env.VITE_ENV === 'dev' ? 'https://members.dev.comparepower.com' : 'https://members.comparepower.com'

const to_title_case = (string) => {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase()
}
export const log_event = function({ name, keep_name, ...rest }) {
  const { user } = Auth()
  if (rest) {
    const value = {}
    for (const key of Object.keys(rest)) {
      value[key] = null
    }
    dataLayer.push({ event: 'clear', ...value })
  }

  const meta_data = {
    mem_event_details: {
      domain,
      ...rest
    }
  }

  if (user.value) {
    const { id, user_metadata, last_sign_in_at } = user.value
    const { first_name, last_name, email, phone, HMAC, agreed_to_terms_at } = user_metadata || {}
    meta_data.user_information = {
      fingerprint_id: fingerprint_id.value,
      user_id: id,
      logged_in_at: Math.round(new Date(last_sign_in_at).getTime() / 1000),
      email,
      ...HMAC && { intercom_hmac_hash: HMAC },
      ...first_name && { name_first: first_name },
      ...last_name && { name_last: last_name },
      ...agreed_to_terms_at && { agreed_to_terms_at: agreed_to_terms_at }
    }
    if (first_name && last_name) {
      meta_data.user_information.name_full = `${first_name} ${last_name}`
      meta_data.user_information.name_full_proper_case = `${to_title_case(first_name)} ${to_title_case(last_name)}`
    }
    if (phone) {
      const stripped_phone = phone.replace(/\D/g, '')
      meta_data.user_information.phone_number_mobile_verified = stripped_phone
    }
  }

  dataLayer.push({
    event: keep_name ? name : `cp_${name}`,
    ...meta_data
  })
}
