import { ref }                                                              from 'vue'
import axios                                                                from 'axios'
import { supabase }                                                         from '@/supabase'
import { rep_list }                                                         from '@/composables/helpers/rep_list'
import { get_all_service_locations_by_user, get_service_location_by_esiid } from '@/services/service_api/service_locations'

const selected_service_location = ref(null)
const service_locations = ref([])
const service_locations_loading = ref(false)
const service_locations_initiated = ref(false)

const get_service_locations = async () => {
  service_locations.value = []
  service_locations_loading.value = true
  try {
    service_locations.value = await get_all_service_locations_by_user()
    service_locations_initiated.value = true
  }
  catch (err) {
    console.error(err)
  }
  finally {
    service_locations_loading.value = false
  }
}

const update_location_in_service_locations = async ({ esiid }) => {
  if (!service_locations_initiated.value) {
    await get_service_locations()
  }

  const location = await get_service_location_by_esiid({ esiid })

  if (location) {
    const index = service_locations.value?.findIndex(x => x.service_location.esiid === esiid)

    if (index !== -1) {
      service_locations.value[index] = location
    }
    else {
      service_locations.value?.unshift(location)
    }
  }
}

const create_centerpoint_usage_request = async ({ esiid, meter_number }) => {
  try {
    const { data: { session: { access_token } } } = await supabase.auth.getSession()
    const { data } = await axios.post(`${ import.meta.env.VITE_SERVICE_URL }/api/service_locations/${ esiid }/centerpoint_usage`,
      { meter_number },
      { headers: { Authorization: `Bearer ${ access_token }` } })
    return data
  }
  catch (err) {
    console.error(err)
    throw err
  }
}
const get_centerpoint_usage = async ({ esiid }) => {
  try {
    const { data: { session: { access_token } } } = await supabase.auth.getSession()
    const { data } = await axios.get(`${ import.meta.env.VITE_SERVICE_URL }/api/service_locations/${ esiid }/centerpoint_usage`,
      { headers: { Authorization: `Bearer ${ access_token }` } })
    return data
  }
  catch (err) {
    console.error(err)
    throw err
  }
}
const get_smt_monthly_usage = async ({ esiid }) => {
  try {
    const { data: { session: { access_token } } } = await supabase.auth.getSession()
    const { data } = await axios.get(`${ import.meta.env.VITE_SERVICE_URL }/api/service_locations/${ esiid }/agreements/usage/monthly`,
      { headers: { Authorization: `Bearer ${ access_token }` } })
    return data
  }
  catch (err) {
    console.error(err)
    throw err
  }
}

const get_retail_electricity_providers = async () => {
  try {
    return rep_list.map(x => ({ label: x.name, value: x.puct_number }))
  }
  catch (err) {
    console.error(err)
    throw err
  }
}
const submit_smt_agreement = async ({ esiid, meter_number, puct_number }) => {
  try {
    const { data: { session: { access_token } } } = await supabase.auth.getSession()
    const { data } = await axios.post(`${ import.meta.env.VITE_SERVICE_URL }/api/service_locations/${ esiid }/agreements`,
      { esiid, meter_number, puct_number },
      { headers: { Authorization: `Bearer ${ access_token }` } })
    return data
  }
  catch (err) {
    const message = err.response?.data?.message
    if (message) {
      throw new Error(message)
    }
    else {
      throw err
    }
  }
}
const confirm_smt_agreement = async ({ esiid }) => {
  try {
    const { data: { session: { access_token } } } = await supabase.auth.getSession()
    const { data } = await axios.patch(`${ import.meta.env.VITE_SERVICE_URL }/api/service_locations/${ esiid }/agreements`,
      { agreed: true },
      { headers: { Authorization: `Bearer ${ access_token }` } })
    return data
  }
  catch (err) {
    const message = err.response?.data?.message
    if (message) {
      throw new Error(message)
    }
    else {
      throw err
    }
  }
}

const save_custom_usage = async ({ esiid, usage }) => {
  try {
    const { data: { session: { access_token } } } = await supabase.auth.getSession()
    const { data } = await axios.post(`${ import.meta.env.VITE_SERVICE_URL }/api/service_locations/${ esiid }/custom_usage`,
      usage,
      { headers: { Authorization: `Bearer ${ access_token }` } })
    return data
  }
  catch (err) {
    const message = err.response?.data?.message
    if (message) {
      throw new Error(message)
    }
    else {
      throw err
    }
  }
}
export default () => {
  return {
    service_locations_initiated,
    selected_service_location,
    service_locations,
    get_service_locations,
    update_location_in_service_locations,
    create_centerpoint_usage_request,
    get_centerpoint_usage,
    get_smt_monthly_usage,
    get_retail_electricity_providers,
    submit_smt_agreement,
    confirm_smt_agreement,
    save_custom_usage,
    service_locations_loading
  }
}