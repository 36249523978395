import { get_auth_header, service_api_service } from '@/services/service_api/index'

export const get_order_by_id = async ({ id }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/orders/${id}`, { headers })
    return await data
  }
  catch (err) {
    console.error(err)
  }
}
export const get_all_orders_for_user = async () => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/orders`, { headers })
    return await data
  }
  catch (err) {
    console.error(err)
  }
}

export const opt_out_of_renewal_by_email = async ({ order_id, email, opt_out_reason }) => {
  const { data } = await service_api_service.patch(`/api/notifications`, { order_id, email, opt_out_reason })
  return await data
}
